import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import _ from 'lodash'
import { Button } from 'reactstrap'
import moment from 'moment'

const VISIBLE_COUNT = 10

class VisitPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleVideos: VISIBLE_COUNT
    }
  }

  viewMoreVideos = () => {
    this.setState({
      visibleVideos: this.state.visibleVideos + VISIBLE_COUNT
    })
  }

  render () {
    return (
      <Layout>
        <SEO title="Sermons" />
        <Container>
        <section style={{height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1>Sermons</h1>
        </section>
        <StaticQuery
          query={graphql`
            query {
              allYoutubeVideo(sort: {fields: publishedAt, order: DESC}) {
                edges {
                  node {
                    id
                    title
                    localThumbnail {
                      publicURL
                    }
                    description
                    videoId
                    publishedAt
                    privacyStatus
                  }
                }
              }
            }
          `}
          render={data => {
            const videos = _.take(data.allYoutubeVideo.edges, this.state.visibleVideos)
            return (videos.map((edge) => {
              return (
                <SermonCell
                  title={edge.node.title}
                  youtubeId={edge.node.videoId}
                  author='Jay Jailal'
                  date={edge.node.publishedAt}
                  description={edge.node.description}
                  image={edge.node.localThumbnail.publicURL} />
              )
            }))
          }}
        />
        <div style={{height: 100, widht: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <Button onClick={this.viewMoreVideos} size='lg' color="secondary">See More Videos</Button>
        </div>
        </Container>
      </Layout>
    )
  }
} 

const SermonCell = ({title, image, author, date, description, youtubeId}) => (
  <Row className='sermon-cell-container'>
    <Col md={{size: 5}}>
      <Link to={`/sermon/${youtubeId}`}>
        <img className='sermon-cell-image' alt='' src={image} />
      </Link>
    </Col>
    <Col md={{size: 7}}>
      <Link to={`/sermon/${youtubeId}`}><h2 className='sermon-cell-title'>{title}</h2></Link>
      <p className='sermon-cell-meta'>{`${author} - ${moment(date).format('MMMM DD, YYYY')}`}</p>
      <p className='sermon-cell-description'>{description}</p>
    </Col>
  </Row>
)

export default VisitPage
